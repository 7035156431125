import apiInstance from '../axios/axios';
import { convertSnakeToCamel, parseResponseToJSON } from '../utils/utils';

export default {
  async getAllCollaborations() {
    try {
      const res = await apiInstance.get('latestCollaborations');
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },
  async getAllUserCollaborations() {
    try {
      const res = await apiInstance.get(`getStudentCollaborations`);
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },

  async create(payload) {
    try {
      const res = await apiInstance.post('addThesis', payload);
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },

  async update(payload) {
    try {
      const collaboration = {...payload.collaborationDetails}
      delete collaboration._id
      delete collaboration.Id
      const res = await apiInstance.post(`updateThesis/${payload.id}`, collaboration);
      return res.data
      // console.log("🚀 ~ file: collaborationService.js:37 ~ update ~ res:", res)
    } catch (error) {
      throw error;
    }
  },

  async getLatest() {
    try {
      const res = await apiInstance.get('latestCollaborations');
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },

  async getById(id) {
    try {
      const res = await apiInstance.get(`getThesis/${id}`);
      const camelCaseData = convertSnakeToCamel(res.data.payload)
      return parseResponseToJSON(camelCaseData); // why are we returning an array?
    } catch (error) {
      throw error;
    }
  },

  async updateOfferStatus(data) {
    try {
      const res = await apiInstance.post('changeOfferStatus', data);
      return parseResponseToJSON(res.data.payload)
    } catch (error) {
      throw error;
    }
  },

  async addThesisInvite(payload) {
    try {
      const res = await apiInstance.post('addThesisInvite', payload);
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },

  async newOffer(payload) {
    const res = await apiInstance.post('sendInvitation', payload);
    return parseResponseToJSON(res.data.payload)
  },

  async updateThesisStatus(payload) {
    const res = await apiInstance.post('changeThesisStatus', payload);
    return parseResponseToJSON(res.data.payload)
  },

  async cancelThesisInvite(payload) {
    const res = await apiInstance.post('cancelThesisInvite',payload);
    return parseResponseToJSON(res)
    // return res
  },
  async acceptThesisInvitation(payload) {
    const res = await apiInstance.post('acceptThesisInvite', payload);
    // console.log("🚀 ~ file: collaborationService.js:97 ~ acceptThesisInvitation ~ res:", res)
    // return parseResponseToJSON(res)
  },

  async getUserInvitations() {
    const res = await apiInstance.get(`getStudentInviteThesis`)
    return res.data.payload
  },

  async removeThesisMember(payload){
    const res = await apiInstance.post('removeThesisMember', payload)
  }

};
