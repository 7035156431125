<template>
  <div id="app">
    <!-- <div class="app-header" style="display:none">
      <h5>
        Secure your free spot at
        <strong> Excelerate Connect 2024</strong>
        career event in Copenhagen
      </h5>
      <a
        href="https://excelerate.dk/en/excelerate-connect/"
        target="_blank"
        class="event-button btn"
        >Get early bird access
      </a>
    </div> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  //placeholder view, see router/index.js for children views
}
</script>


<style lang="scss">
html, body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>
<style scoped>
/* .app-header {
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #bc1e73;
    @media (max-width: 499px){
    flex-direction: column;
  }
}
.app-header h5 {
  color: #fff;
  margin: 10px;
  font-weight: 200;
  font-size: 13px;
  text-align: center;
}
.event-button {
  background: #fff;
  color: var(--pink);
  padding: 1px 4px !important;
  font-weight: 400;
}
.event-button:hover {
  color: var(--pink);
} */
</style>