import moment from 'moment';
import { Store } from 'vuex';

export function convertSnakeToCamel(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  return Object.keys(obj).reduce((result, key) => {
    const camelKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    const value = obj[key];

    if (Array.isArray(value)) {
      result[camelKey] = value.map((item) => convertSnakeToCamel(item));
    } else {
      result[camelKey] = convertSnakeToCamel(value);
    }

    return result;
  }, {});
}

export function calculateSemester(month, year) {
  const prevYearArr = ['January', 'February', 'March', 'April'];
  const currentSummerArr = ['May', 'June', 'July', 'August', 'September', 'October'];
  const currentWinterArr = ['November', 'December'];
  return currentSummerArr.includes(month)
    ? `Summer ${year}`
    : currentWinterArr.includes(month)
      ? `Winter ${year}`
      : prevYearArr.includes(month) ? `Winter ${year-1}`: null;
  // just creating this but waiting on kaspar to confirm its usage
  // const sem = springArr.includes(this.formData.graduatingMonth) ? "Summer" : fallArr.includes(this.formData.graduatingMonth) ? "Winter" : null
  // console.log(sem)
}
export function calculateEstimatedSemester(month, year) {
// Fall = July-August-September-October-November-December
// Spring = January-Febuary-March-April-May-June
  const springArr = ['January', 'February', 'March', 'April', 'May', 'June'];
  const fallArr = [
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return springArr.includes(month)
    ? `Spring ${year}`
    : fallArr.includes(month)
      ? `Fall ${year}`
      : null;
  // just creating this but waiting on kaspar to confirm its usage
  // const sem = springArr.includes(this.formData.graduatingMonth) ? "Summer" : fallArr.includes(this.formData.graduatingMonth) ? "Winter" : null
  // console.log(sem)
}
export function calculateSession(month, year) {
  const springArr = ['May', 'June', 'July', 'August', 'September', 'October'];
  const fallArr = [
    'November',
    'December',
    'January',
    'February',
    'March',
    'April',
  ];
  return springArr.includes(month)
    ? 'Summer'
    : fallArr.includes(month)
      ? 'Winter'
      : null;
}

export function calculateCurrentSemester(month, year) {
  const springArr = ['May', 'June', 'July', 'August', 'September', 'October'];
  const fallArr = [
    'November',
    'December',
    'January',
    'February',
    'March',
    'April',
  ];
    const evenorOdd = springArr.includes(month)
    ? 'Summer'
    : fallArr.includes(month)
      ? 'Winter'
      : null;
 }
export function checkIsGraduated(month, year) {
  const currentMonth = moment().format('MMMM');
  const currentYear = moment().year();

  const givenDate = moment(`${month} ${year}`, 'MMMM YYYY');
  const currentDate = moment(`${currentMonth} ${currentYear}`, 'MMMM YYYY');

  return givenDate.isSameOrBefore(currentDate);
}

export function calculateSignUpStep(status) {
  if (status && status >= 0 && status < 50) {
    return 'Explorer';
  } else if (status && status >= 50 && status < 100) {
    return 'Rising Star';
  } else if (status && status === 100) {
    return 'Talent';
  }
  return null;
}

export function calculateThesisPeriod(month, year) {
  const currentMonth = moment().format('MMMM');
  const currentYear = moment().year();

  const givenDate = moment(`${month} ${year}`, 'MMMM YYYY');
  const currentDate = moment(`${currentMonth} ${currentYear}`, 'MMMM YYYY');

  return givenDate.isSameOrBefore(currentDate);
}

export function calculateFullDate(month, year) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${months[month - 1]} ${year}`;
}

export function getLocationTypeName(id) {
  const locationType = [
    { id: 2, value: 'Hybrid' },
    { id: 1, value: 'On-site' },
    { id: 3, value: 'Remote' },
  ];
  return locationType.find((location) => location.id === id).value;
}

export function parseResponseToJSON(obj) {
  // Base case: If the input is not an object or is null, return the input as-is
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // If the input is an array, parse each element of the array recursively
  if (Array.isArray(obj)) {
    return obj.map((item) => parseResponseToJSON(item));
  }

  // If the input is an object, parse each property value recursively
  const parsedObject = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let parsedValue;

      // Check if the property is "semesterCount"
      if (key === 'semesterCount' || key === 'courses') {
        // Assign the value directly without parsing
        parsedValue = obj[key];
      } else {
        // For other properties, attempt to parse the JSON value
        try {
          parsedValue = JSON.parse(obj[key]);
        } catch (error) {
          // If parsing fails, keep the value as-is
          parsedValue = obj[key];
        }
      }

      parsedObject[key] = parseResponseToJSON(parsedValue);
    }
  }

  return parsedObject;
}

export function toBase64(val) {
  if (!val) return;
  const value = val.toString();
  return Buffer.from(value).toString('base64');
}

export function base64ToString(val) {
  if (!val) return '';
  let buff = Buffer(val, 'base64');
  let base64ToStringNew = buff.toString('ascii');
  return base64ToStringNew;
}
