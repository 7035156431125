import store from '@/store';
import router from '../../router';

import authService from '../../services/authService'
import apiInstance from '../../axios/axios';
import { toBase64 } from '../../utils/utils';

const state = {
  errorMessage: null,
  studentSignUpData: null,
  professionalSignUpData: null,
  isUpdating: false,
};

const getters = {
  studentSignupData(state) {
    return state.studentSignUpData;
  },
  professionalSignupData(state) {
    return state.professionalSignUpData;
  },
  authErrorMessage(state) {
    return state.errorMessage;
  },
  authIsUpdating: (state) => state.isUpdating
};
const actions = {
  clearAuthState({commit}) {
    commit('clearError', null);
    commit('setIsUpdating', false);
  },
  async registerUser({ commit }, payload) {
    commit('clearError', null);
    commit('setIsUpdating', true);
    try {
      const res = await authService.registerUser(payload);
      localStorage.setItem('u-verified', 0)
      if (payload.payload === 'Professional') {
        commit('setIsUpdating', false);
        localStorage.setItem('i_am',  toBase64('professional'));
        return router.push('/user/welcome').catch(() => {});
      }
      if (payload.payload === 'Student') {
        commit('setIsUpdating', false);
        localStorage.setItem('i_am', toBase64('student'));
        return router.push('/user/student-welcome').catch(() => {});
      }
    } catch (error) {
      commit('setError', error.response.data.message)
      commit('setIsUpdating', false);
    }
  },
  async signIn({ commit }, payload) {
    commit('clearError', null);
    commit('setIsUpdating', true);
    try {
      const res = await authService.signInUser(payload)

        if(res.isVerified == 0 || res.isVerified == 1) {
          localStorage.setItem('u-verified', res.isVerified);
        } else{
          localStorage.setItem('u-verified', 0);
        }
        if(res.isVerified) {
          router.push('/').catch(() => {});
        }else {
          router.push('/user/student-welcome').catch(() => {});
        }
      commit('setIsUpdating', false);
    } catch (error) {
      commit('setError', error)
      commit('setIsUpdating', false);
    }
  },
  async getStudentSignupData({ commit }) {
    commit('clearError', null);
    try {
      const res = await apiInstance.get();
      commit('setStudentSignUpData', res.data.payload);
    } catch (error) {
      commit('setError', error);
    }
  },
  // async getProfessionalSignupData({ commit }, payload) {
  //   commit('clearError', null);
  //   try {
  //     const res = await apiInstance.get();
  //     commit('setProfessionalSignUpData', res.data.payload);
  //   } catch (error) {
  //     commit('setError', 'something went wrong!');
  //   }
  // },
};

const mutations = {
  setStudentSignUpData(state, payload) {
    state.studentSignUpData = payload;
  },
  setProfessionalSignUpData(state, payload) {
    state.professionalSignUpData = payload;
  },
  clearError(state, payload) {
    state.errorMessage = payload;
  },
  setError(state, payload) {
    state.errorMessage = payload;
  },
  setIsUpdating(state, payload) {
    state.isUpdating = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
