import store from '@/store';
import apiInstance from '../../axios/axios';
import generalService from '../../services/generalService';

const state = {
  industries: null,
  universities: null,
  skills: {
    softSkills: null,
    hardSkills: null,
  },
  isLoading: false,
  researchInterests: [],
  jobInterests: null,
  // studentContent: null,
  // professionalContent: null,
};

const getters = {
  industries(state) {
    return state.industries;
  },
  universities(state) {
    return state.universities;
  },
  allSkills(state) {
    return state.skills;
  },
  generalIsLoading: (state) => state.isLoading,

  researchInterests: (state) => state.researchInterests,

  jobInterests: (state) => state.jobInterests,

  // studentContent: (state) => state.studentContent,

  // professionalContent: (state) => state.professionalContent,
};

// handleApiError({ commit }, error); this is how the function gets called
const handleApiError = ({ commit }, error) => {
  // if (error.response) {
  //   // Handle error response from the server
  //   const errorResponse = error.response.data;
  //   if (errorResponse.validationErrors) {
  //     // Handle validation errors
  //     commit('setError', errorResponse.validationErrors.join(', '));
  //   } else {
  //     // Handle other error response
  //     commit('setError', 'Something went wrong!');
  //   }
  // } else if (error.request) {
  //   // Handle network errors
  //   commit('setError', 'Network error. Please try again later.');
  // } else {
  //   // Log unexpected errors
  //   console.error('Unexpected error:', error);
  //   commit('setError', 'Something went wrong. Please try again later.');
  // }
};

const actions = {
  async getIndustries({ commit }, payload) {
    try {
      const res = await apiInstance.get('getIndustries');
      commit('setIndustries', res.data.data.payload);
    } catch (error) {
      handleApiError({ commit }, error);
    }
  },
  async getUniversities({ commit }, payload) {
    commit('setIsLoading', true);
    try {
      const res = await generalService.getUniversities();
      commit('setIsLoading', false);
      const universities = res.universities.sort(function (a, b) {
        // Check if the university_name is "Other" for both entries
        if (a.university_name === 'Other' && b.university_name === 'Other') {
          return 0; // Preserve the original order for "Other"
        } else if (a.university_name === 'Other') {
          return 1; // "Other" should come after any other university_name
        } else if (b.university_name === 'Other') {
          return -1; // "Other" should come after any other university_name
        } else {
          // For non-"Other" university_names, sort alphabetically
          return a.university_name.localeCompare(b.university_name);
        }
      });
      commit('setUniversities', { ...res, universities });
    } catch (error) {
      commit('setIsLoading', false);
      handleApiError({ commit }, error);
    }
  },

  async getAllSkills({ commit }, payload) {
    try {
      const res = await generalService.getAllSkills();
      commit('setSkills', res);
    } catch (error) {
      handleApiError({ commit }, error);
    }
  },

  async getResearchInterests({ commit }) {
    commit('setIsLoading', true);
    try {
      // todo extract to service
      const res = await generalService.getAllResearchInterests();
      res?.sort(function (a,b) {
        return a.category_name.localeCompare(b.category_name);
      })
      commit('setResearchInterests', res);
      commit('setIsLoading', false);
    } catch (error) {
      commit('setIsLoading', false);
      console.log('err');
    }
  },

  async getJobInterests({ commit }) {
    commit('setIsLoading', true);
    try {
      const res = await generalService.getJobInterests();
      commit('setJobInterests', res);
      commit('setIsLoading', false);
    } catch (error) {
      commit('setIsLoading', false);
      commit('setError', error);
    }
  },

  async getStudentContent({ commit }) {
    commit('setIsLoading', true);
    try {
      const res = await generalService.getStudentContent();
      commit('setStudentContent', res);
      commit('setIsLoading', false);
    } catch (error) {
      commit('setIsLoading', false);
    }
  },

  async getProfessionalContent({ commit }) {
    commit('setIsLoading', true);
    try {
      const res = await generalService.getProfessionalContent();
      commit('setProfessionalContent', res);
      commit('setIsLoading', false);
    } catch (error) {
      commit('setIsLoading', false);
    }
  },
};

const mutations = {
  setIndustries(state, payload) {
    state.industries = payload;
  },
  setUniversities(state, payload) {
    state.universities = payload;
  },
  setSkills(state, payload) {
    state.skills.softSkills = payload.softskills;
    state.skills.hardSkills = payload.hardskills;
  },

  setResearchInterests(state, payload) {
    state.researchInterests = payload;
  },

  setIsLoading(state, payload) {
    state.isLoading = payload;
  },

  setJobInterests: (state, payload) => (state.jobInterests = payload),

  setStudentContent: (state, payload) => (state.studentContent = payload),

  setProfessionalContent: (state, payload) =>
    (state.professionalContent = payload),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
