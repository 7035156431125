import { computed } from 'vue';
import apiInstance from '../axios/axios';
import {convertSnakeToCamel, toBase64} from '../utils/utils'

export default {
  async registerUser(payload) {
    try {
      const verificationCode = Math.floor(100000 + Math.random() * 900000);
      const res = await apiInstance.post('create-user', {
        ...payload,
        verificationCode,
      });
      localStorage.setItem('auth-token', toBase64(res.data.payload.token));
      localStorage.setItem('uid', toBase64(res.data.payload.data.id));
      localStorage.setItem('user_code', toBase64(res.data.payload.data.qrcode));
      // localStorage.setItem('i_am', toBase64(res.data.payload.data.userType));
      localStorage.setItem('c_prog', null);
      // console.log(payload.payload)
      // todo call get university action here
      return res.data.payload;
    } catch (error) {
      throw error;
    }
  },
  async signInUser(payload) {
    try {
      const response = await apiInstance.post('login', payload);
      const computedResponse = convertSnakeToCamel(response.data.payload);
      if(computedResponse.noAuth) {
        const error = new Error('Login has been disabled, someone from our team will contact you soon.')
        throw error
      }
      // apiInstance.interceptors.request.handlers = []
      localStorage.setItem('auth-token', toBase64(computedResponse.token));
      localStorage.setItem('uid', toBase64(computedResponse.userId));
      localStorage.setItem('user_code', toBase64(computedResponse.qrcode));
      localStorage.setItem('i_am', toBase64(computedResponse.userType));
      localStorage.setItem('c_prog', computedResponse.currentProgress);

      return computedResponse;
    } catch (error) {
      throw error?.response?.data?.message ?? 'Invalid credentials';
    }
  },
};
