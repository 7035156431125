import router from '../../router';
import collaborationService from '../../services/collaborationService';

const state = {
  allCollaborations: [],
  userCollaborations: [],
  latestCollaborations: [],
  // currentCollaboration: null,
  // collaborationInvites: [],
  // collaborationOffers: [],
  isLoading: false,
  isUpdating: false,
};

const getters = {
  allCollaborations: (state) => state.allCollaborations,

  collaborationIsLoading: (state) => state.isLoading,

  userCollaborations: (state) => state.userCollaborations,

  latestCollaborations: (state) => state.latestCollaborations,

  // currentCollaboration: (state) => state.currentCollaboration,

  // collaborationInvites: (state) => state.collaborationInvites,

  // collaborationOffers: (state) => state.collaborationOffers,

  collaborationIsUpdating: (state) => state.isUpdating,
};

const actions = {
  async getAllCollaborations({ commit }) {
    commit('setLoadingState', true);
    commit('setCurrentCollaborationInvites', null);
    commit('setCurrentCollaboration', null);
    try {
      const res = await collaborationService.getAllCollaborations();
      commit('setAllCollaborations', res);
      commit('setLoadingState', false);
    } catch (error) {
      commit('setLoadingState', false);
      console.log('dd');
    }
  },
  async getAllUserCollaborations({ commit }) {
    commit('setLoadingState', true);
    commit('setCurrentCollaborationInvites', null);
    commit('setCurrentCollaboration', null);
    try {
      const res = await collaborationService.getAllUserCollaborations();
      commit('setUserCollaborations', res);
      commit('setLoadingState', false);
    } catch (error) {
      commit('setLoadingState', false);
    }
  },

  // async newCollaboration({ commit }, payload) {
  //   commit('setIsUpdating', true);
  //   commit('setLoadingState', true);
  //   commit('setCurrentCollaborationInvites', null);
  //   commit('setCurrentCollaboration', null);
  //   try {
  //     const res = await collaborationService.create(payload);
  //     commit('setIsUpdating', false);
  //     router.push(`/collaboration/edit/${res._id.$oid}?new=collaboration`); // note sure if the $oid is the best way to go
  //   } catch (error) {
  //     commit('setIsUpdating', false);
  //     commit('setLoadingState', false);
  //   }
  // },

  // async updateCollaboration({ commit }, payload) {
  //   commit('setIsUpdating', true);
  //   try {
  //     const res = await collaborationService.update(payload);
  //     commit('setCurrentCollaboration', { ...payload.collaborationDetails });
  //     commit('setIsUpdating', false);
  //   } catch (error) {
  //     // commit('setLoadingState', false);
  //     commit('setLoadingState', false);
  //   }
  // },

  async getLatestCollaborations({ commit }, payload) {
    commit('setLoadingState', true);
    commit('setCurrentCollaborationInvites', null);
    commit('setCurrentCollaborationOffers', null);
    commit('setCurrentCollaboration', null);
    try {
      const res = await collaborationService.getLatest(payload);
      commit('setLatestCollaborations', res);
      // console.log(res, 'worked?');
      // commit('setCollaborations', res);
    } catch (error) {
      commit('setLoadingState', false);
    }
  },

    clearCollaborations({commit}) {
    commit('clearData')
  },

  async getCollaboration({ commit }, payload) {
    commit('setLoadingState', true);
    try {
      const { thesis, invites, offers } = await collaborationService.getById(
        payload
      );
      commit('setLoadingState', false);
      commit('setCurrentCollaboration', thesis);
      commit('setCurrentCollaborationInvites', invites);
      commit('setCurrentCollaborationOffers', offers);
    } catch (error) {
      commit('setLoadingState', false);
    }
  },

  async updateThesisStatus({ commit }, data) {
    commit('setLoadingState', true);
    try {
      const res = await collaborationService.updateThesisStatus({thesisId: data.thesisId, status: data.status});
      commit('setLoadingState', false);
      commit('setCurrentCollaboration', null);
      commit('setCurrentCollaborationInvites', null);
      router.push('/collaboration/my');
    } catch (error) {
      commit('setLoadingState', false);
    }
  },

  async inviteMembers({ commit, state }, payload) {
    // commit('setLoadingState', true);
    try {
      const res = await collaborationService.addThesisInvite(payload)
    // commit('setCurrentCollaborationInvites', null);
    return res

      // commit('setLoadingState', false);
    } catch (error) {
      // commit('setLoadingState', false);
    }
  },

  async newOffer({ commit }, payload) {
    commit('setLoadingState', true);
    try {
      const res = await collaborationService.newOffer(payload);
    } catch (error) {
      commit('setLoadingState', false);
    }
  },

  async updateOfferStatus({ commit }, payload) {
    commit('setIsUpdating', true);
    try {
      const res = await collaborationService.updateOfferStatus(payload);
      commit('setCurrentCollaboration', res);
    } catch (error) {
      commit('setIsUpdating', false);
    }
  },
  resetCollaborationLoadingState({ commit }) {
    commit('setLoadingState', false);
  },
  async deleteThesisInvite({commit, state}, payload) {
    commit('setIsUpdating', true);
    try {
      const res = await collaborationService.cancelThesisInvite(payload);
      const invitesArray = state.collaborationInvites.filter(invite => invite.targetEmail !== payload.targetEmail)
      commit('setCurrentCollaborationInvites', invitesArray)
    } catch (error) {
      commit('setIsUpdating', false);
    }
  },
  async acceptThesisInvitation({commit, state}, payload) {
    commit('setIsUpdating', true);
    try {
      const res = await collaborationService.acceptThesisInvitation(payload);
    } catch (error) {
      commit('setIsUpdating', false);
    }
  },

};

const mutations = {
  setAllCollaborations: (state, collaborations) =>
    (state.allCollaborations = collaborations),

  setLoadingState: (state, payload) => (state.isLoading = payload),

  setUserCollaborations: (state, payload) =>
    (state.userCollaborations = payload),

  setLatestCollaborations: (state, payload) =>
    (state.latestCollaborations = payload),

  setCurrentCollaboration: (state, payload) =>
    (state.currentCollaboration = payload),

  setCurrentCollaborationInvites: (state, payload) =>
    (state.collaborationInvites = payload),

  setIsUpdating: (state, payload) => (state.isUpdating = payload),

  setCurrentCollaborationOffers: (state, payload) =>
    (state.collaborationOffers = payload),

  clearData(state) {
  state.allCollaborations = []
  state.userCollaborations =[]
  state.latestCollaborations= []
  state.isLoading = false
  state.isUpdating = false
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
