import axios from "axios";
import store from "@/store";
import { base64ToString } from "../../utils/utils";

const state = {
    signupData: '',
};

const getters = {
    signupData(state) {
        return state.signupData
    },
}

const actions = {
  async setSignupData({ commit }, payload) {
    commit("clearSignupData")
    let token = localStorage.getItem('auth-token')
    token = base64ToString(token)
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}signup-data/${payload}` ,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    //   commit("setSubscription", res.data.payload);
    //   return res.data.payload;
      commit("setSignUpData", res.data.payload);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setSignUpData(state, payload) {
    state.signupData = payload;
  },
  clearSignupData(state) {
    state.signupData = ''
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};