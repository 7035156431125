import apiInstance from '../axios/axios';
import { convertSnakeToCamel, parseResponseToJSON } from '../utils/utils';

export default {
  async getSignupData() {
    try {
      const res = await apiInstance.get('getSkills');
      return parseResponseToJSON(res);
    } catch (error) {
      throw error;
    }
  },

  async professionalSignupData() {
    try {
      const res = await apiInstance.get('getProfessionalPageContent');
    } catch (error) {
      throw error;
    }
  },
  async studentSignupData() {
    try {
      const res = await apiInstance.get('getStudentPageContent');
    } catch (error) {
      throw error;
    }
  },

  async getUserData() {
    try {
      const res = await apiInstance.get(`getUserDetails`);
      const computedResponse = convertSnakeToCamel(res.data.payload);
      return parseResponseToJSON(computedResponse);
    } catch (error) {
      throw error;
    }
  },
  async updateProfessional(payload) {
    try {
      const res = await apiInstance.post('professional-signup-step2', payload);
      const computedResponse = convertSnakeToCamel(res.data.payload);
      return parseResponseToJSON(computedResponse);
    } catch (error) {
      throw error;
    }
  },
  async verifyUserAccount(payload) {
    try {
      const res = await apiInstance.post('verifyUser', payload);
      const computedResponse = convertSnakeToCamel(res.data.payload);
      return parseResponseToJSON(computedResponse);
    } catch (error) {
      throw error;
    }
  },
  async updateStudent(payload) {
    try {
      const res = await apiInstance.post('student-signup-step2', payload);
      const computedResponse = convertSnakeToCamel(res.data.payload);
      return parseResponseToJSON(computedResponse);
    } catch (error) {
      throw error;
    }
  },

  async updateStudentCompetencies(payload) {
    try {
      const res = await apiInstance.post('std-competencies', {
        competencies: payload.competencies,
        currentProgress: payload.currentProgress,
        stepsCompleted: payload.step
      });
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },

  async updateStudentTalentBio(payload) {
    try {
      const res = await apiInstance.post('std-talentbio', {
        ...payload,
        stepsCompleted: 3,
      });
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },
  async updateExperience(payload) {
    const res = await apiInstance.post('stdexperience', payload);
    return parseResponseToJSON(res.data.payload);
  },

  async uploadTranscript(payload) {
    const res = await apiInstance.post('std-coursefileupload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res
  },
  async uploadUserCv(payload) {
    // todo need better naming convention for the api's
    const res = await apiInstance.post('std-coursecvupload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res
  },

  async updateStudentExperience(payload) {

    Object.values(payload.experience).forEach(item => {
      delete item.createdAt
      delete item.updatedAt
      delete item.deletedAt
      item['user_id'] = item['userId']
      delete item.userId
    })
    try {
      const res = await apiInstance.post('stdExperience', {...payload.experience}, {
      headers: {
        'stepsCompleted': 2,
        'currentProgress': payload.currentProgress,
      },
    })
      return parseResponseToJSON(res)
    } catch (error) {
      throw error;
    }
  },
  async updateStudentEducationDetails(payload) {
    console.log("🚀 ~ file: userService.js:140 ~ updateStudentEducationDetails ~ payload:", payload)
    try {
      const res = await apiInstance.post('std-coursedetails', {courses: payload.educationHistory, semesterCount: payload.semesterCourseCount, isBachelor: 1, isMaster: 1, currentProgress: payload.currentProgress, stepsCompleted: 0, showGrades: payload.showGrades})
      return parseResponseToJSON(res)
    } catch (error) {
      throw error;
    }
  },
  
  async updatePassword(payload) {
    const res = await apiInstance.post('reset-password', {password: payload.userOldPassword, new_password: payload.userNewPassword})
    return parseResponseToJSON(res)

  },

  async removeTranscript(){
    const res = await apiInstance.post('removeFile')
    return parseResponseToJSON(res)
  },
  async removeCv(){
    const res = await apiInstance.post('removeCv')
    return parseResponseToJSON(res)
  },

  async resendVerificationCode(payload) {
    const res = await apiInstance.post('resendVerificationCode', payload)
    return res
  },

  async updateGradeStatus(payload) {
    const res = await apiInstance.post('stdShowGrades', {showGrades: payload })
    return res
  },
  
  async updateCareerMatching(payload){
    const res = await apiInstance.post('careerMatching', {careerMatching: payload})
    return res
  }, 
  async updateBachelorEducation(payload){
    console.log("🚀 ~ file: userService.js:166 ~ updateBachelorEducation ~ payload:", payload)
    const data = { 
      institution: payload.institution, 
      area: payload.area, 
      specializationCourseOne: payload.specializationCourseOne, 
      specializationCourseTwo: payload.specializationCourseTwo, 
      customBachelorInstitutionName: payload.customBachelorInstitutionName,
      customBachelorStudyProgram: payload.customBachelorStudyProgram,
      graduationYear: payload.graduationYear,
      hasCustomProgramme: payload.hasCustomProgramme,
      degree: 'Bachelor',
      degreeType: 'Bachelor',
    }
    const res = await apiInstance.post('std-customBachelor', data)
    return res
  },

};
