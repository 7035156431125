import generalService from '../../services/generalService';

const state = {
  student: null,
  professional: null,
  general: null,
  error: null,
  loading: null,
};

const getters = {
  studentContent: (state) => state.student,
  professionalContent: (state) => state.professional,
  // generalContent: (state) => state.general,
  dynamicContentError: (state) => state.error,
  dynamicIsLoading: (state) => state.isLoading,
  //! All dynamic content for every page will be sent from here and we are parsing it here but only
  //! in english, since we are only working with english for now. Once we decide to use multiple languages
  //! whoever is working will have to send the entire objects without the [1] or whatever number and then
  //! parse the stringified JSON on the page based on the selected language
  signInContent: (state) => state.student?.studentdynamic?.signin?.[1],
  signupContent: (state) => state.student?.studentdynamic?.signup?.[1],
  studentSignupContent: (state) => state.student?.studentdynamic?.signup?.[1],
  professionalSignupContent: (state) => state.professionalContent?.signup?.[1],
  explorerBasicContent: (state) => state.student?.studentdynamic?.basicdetails?.[1],
  explorerEducationContent: (state) =>  state.student?.studentdynamic?.studybackground?.[1],
  explorerResearchContent: (state) =>  state.student?.studentdynamic?.researchinterests?.[1],
  explorerThesisContent: (state) =>  state.student?.studentdynamic?.thesisprogress?.[1],
  explorerVerifyContent: (state) =>  state.student?.studentdynamic?.thesisprogress?.[1],
  explorerDidYouKnowContent: (state) =>  state.student?.studentdynamic?.didyouknow?.[1],
  explorerDidYouKnowBenefitsContent: (state) => state.student?.studentcollaborationbenefitslist,
  explorerCongratsContent: (state) =>  state.student?.studentdynamic?.congratulationsexplorer?.[1],
  risingOverviewContent: (state) =>  state.student?.studentrisingdynamic?.profileoverview?.[1],
  risingCompetenciesContent: (state) =>  state.student?.studentrisingdynamic?.competencies?.[1],
  risingOverviewRisingListContent: (state) => state.student?.studentrisinglist,
  risingOverviewTalentListContent: (state) => state.student?.studenttalentlist,
  risingExperienceContent: (state) =>  state.student?.studentrisingdynamic?.experience?.[1],
};

const actions = {
  async getStudentContent({ commit }) {
    commit('setLoading', true);
    try {
      const res = await generalService.getStudentContent();
      commit('setLoading', false);
      commit('setStudent', res);
    } catch (error) {
      commit('setLoading', false);
    }
  },
  async getProfessionalContent({ commit }) {
    commit('setLoading', true);
    try {
      const res = await generalService.getProfessionalContent();
      commit('setLoading', false);
      commit('setProfessional', res.data);
    } catch (error) {
      commit('setLoading', false);
    }
  },
  // async generalContent({ commit }) {
  //   commit('setLoading', true);
  //   try {
  //     const res = await generalService.getStudentContent();
  //     commit('setLoading', false);
  //     commit('setStudent', res);
  //   } catch (error) {
  //     commit('setLoading', false);
  //   }
  // },
};

const mutations = {
  setStudent: (state, payload) => (state.student = payload),
  setGeneral: (state, payload) => (state.general = payload),
  setProfessional: (state, payload) => (state.professional = payload),
  setLoading: (state, payload) => (state.loading = payload),
  setError: (state, payload) => (state.error = payload),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
