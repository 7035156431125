// import store from '@/store';
import router from '../../router';
import userService from '../../services/userService';

const state = {
  errorMessage: null,
  studentSignUpData: null,
  professionalSignUpData: null,
  professional: null,
  student: null,
  isLoading: false,
  isUpdating: false,
  message: null,
};

const getters = {
  userErrorMessage(state) {
    return state.errorMessage;
  },

  userMessage(state) {
    return state.message
  },

  userIsLoading(state) {
    return state.isLoading;
  },

  studentResearchInterests(state) {
    return state.student && state.student.researchInterests
      ? state.student.researchInterests
      : [];
  },

  studentData(state) {
    return state.student;
  },

  studentThesisStatus(state) {
    return state.student.thesisStatus;
  },

  professionalData: (state) => state.professional,

  userIsUpdating: (state) => state.isUpdating,

  isZealandFlow: (state) => state.student ? state.student.institution.name.toLowerCase().includes('zealand') : false

};

// handleApiError({ commit }, error); this is how the function gets called
const handleApiError = ({ commit }, error) => {

  if (error.response) {
    // Handle error response from the server
    const errorResponse = error.response.data;

    if (errorResponse.validationErrors) {
      // Handle validation errors
      commit('setError', errorResponse.validationErrors.join(', '));
      throw error
    } else {
      // Handle other error response
      commit('setError', 'Something went wrong, please try again.');
      throw error
    }
  } else if (error.request) {
    // Handle network errors
    commit('setError', 'Network error. Please try again later.');
    throw error
  } else {
    // Log unexpected errors
    console.error('Unexpected error:', error);
    commit('setError', 'Something went wrong. Please try again later.');
    throw error
  }

};

const actions = {
  //professionals
  async getProfessionalSignupData({ commit }) {
    commit('clearError', null);
    try {
      const res = await userService.getSignupData();
      commit('setProfessionalSignUpData', res.data.payload);
    } catch (error) {
      commit('setError', error);

    }
  },

  async getProfessionalData({ commit }, payload) {
    commit('clearError', null);
    commit('resetStudent')
    try {
      const res = await userService.getUserData();
      commit('setProfessionalUserData', {...res.userDetails, companyId: 'Bx875ueZoFPMpRbK7hIo',
        subscriptionId: 2,
        subscription: 1});
    } catch (error) {
      commit('setError', error);
    }
  },

  clearUser({commit}) {
    commit('clearData')
  },

  async updateProfessional({ commit }, payload) {
    commit('clearError', null);
    try {
      const res = await userService.updateProfessional(payload);
      commit('setProfessionalUserData', res);
    } catch (error) {
      commit('setError', error);
    }
  },

  async verifyProfessional({ commit }, payload) {
    commit('clearError', null);
    try {
      const res = await userService.verifyUserAccount(payload);
      return res
      // return router.push('/').catch(() => {});
    } catch (error) {
      commit('setError', error);
    }
  },

  // professionals
  //-----------------------//
  // students
  async updateStudent({ commit }, payload) {
    commit('clearError', null);
    commit('setUpdating', true);
    try {
      const res = await userService.updateStudent(payload);
      commit('setStudentUserData', res);
      commit('setUpdating', false);
    } catch (error) {
      commit('setUpdating', false);
      commit('setError', error.response.data.message);
    }
  },

  async getStudentData({ commit }, ) {
    commit('clearError', null);
    commit('setLoadingState', true);
    commit('resetProfessional')
    try {
      const res = await userService.getUserData();
      // console.log(res.userDetails, 'ud')
      commit('setStudentUserData', res.userDetails);
      commit('setLoadingState', false);
    } catch (error) {
      commit('setLoadingState', false);
      commit('setError', error);
    }
  },

  async verifyStudent({ commit }, payload) {
    commit('clearError', null);
    try {
      const res = await userService.verifyUserAccount(payload);
      return res;
        // return commit('setError', 'Invalid code');
    } catch (error) {
      commit('setError', error.response.data.message);
    }
  },

  async updateStudentCompetencies({ commit }, payload) {
    commit('clearError', null);
    commit('setLoadingState', true);
    commit('setUpdating', true);
    try {
      await userService.updateStudentCompetencies(payload);
      commit('setLoadingState', false);
      commit('setUpdating', false);
    } catch (error) {
      commit('setLoadingState', false);
      commit('setUpdating', false);
      commit('setError', error);
    }
  },

  async updateStudentTalentBio({ commit }, payload) {
    commit('clearError', null);
    commit('setLoadingState', true);
    commit('setUpdating', true);
    try {
      await userService.updateStudentTalentBio(payload);
      commit('setLoadingState', false);
      commit('setUpdating', false);
    } catch (error) {
      commit('setLoadingState', false);
      commit('setUpdating', false);
      commit('setError', error);
      throw error
    }
  },

  async uploadTranscript({commit}, payload) {
    commit('clearError', null);
    try {
      await userService.uploadTranscript(payload)
    } catch (error) {
      throw error
    }
  },
  async uploadCv({commit}, payload) {
    commit('clearError', null);
    try {
      await userService.uploadUserCv(payload)
    } catch (error) {
      throw error
    }
  },

  async updateStudentExperience({commit}, payload) {
    commit('clearError', null)
    commit('setUpdating', true);
    try {
      await userService.updateStudentExperience(payload)
      commit('setUpdating', false);
    } catch (error) {
      commit('setUpdating', false);
      console.log(error)
    }
  },
  async updateEducationDetails({commit}, payload) {
    commit('clearError', null)
    commit('setUpdating', true);
    try {
      await userService.updateStudentEducationDetails(payload)
      commit('setUpdating', false);
    } catch (error) {
      commit('setUpdating', false);
      console.log(error)
    }
  },
  async setGrades({commit}, payload) {
    commit('clearError', null)
    commit('setUpdating', true);
    try {
      await userService.updateGradeStatus(payload)
      commit('setUpdating', false);
    } catch (error) {
      commit('setUpdating', false);
      console.log(error)
    }
  },

  // students
  clearUserError({ commit }) {
    commit('clearError', null);
  },

  resetUser({commit}) {
    commit('resetUser');
  },
  resetUserMessage({ commit }) {
    commit('setMessage', null);
  },

  async updatePassword({commit}, payload) {
    commit('clearError', null)
    commit('setLoadingState', true);
    try {
      const res = await userService.updatePassword(payload)
      commit('setLoadingState', false);
      commit('setMessage', 'password updated successfully');
      
    } catch (error) {
      commit('setError', error.response.data.message || 'Invalid current/old password')
    }
  },

  async removeTranscript({commit}) {
    try {
      const res = await userService.removeTranscript()
      return res
    } catch (error) {
      commit('setError', error.response.data.message || 'Failed to remove transcript')
      throw error
    }
  },

  async resendVerificationCode({commit}, payload) {
    try {
      const res = await userService.resendVerificationCode(payload)
    } catch (error) {
      throw new Error('Could not send verification code')
    }
  },
  async updateCareerMatching({commit}, payload) {
    commit('setUpdating', true);
    try {
      const res = await userService.updateCareerMatching(payload)
      commit('setUpdating', false);
    } catch (error) {
      commit('setUpdating', false);
      throw new Error('Could not update status')
    }
  },
  async updateBachelorEducationDetails({commit}, payload) {
    commit('setUpdating', true);
    try {
      const res = await userService.updateBachelorEducation(payload)
      commit('setUpdating', false);
    } catch (error) {
      commit('setUpdating', false);
      throw new Error('Could not update status')
    }
  }
};

const mutations = {
  clearError(state, payload) {
    state.errorMessage = payload;
  },
  setMessage(state, payload) {
    state.message = payload;
  },

  setError(state, payload) {
    state.errorMessage = payload;
  },

  setProfessionalUserData(state, payload) {
    state.professional = payload;
  },

  setStudentUserData(state, payload) {
    state.student = payload;
  },

  setLoadingState(state, payload) {
    state.isLoading = payload
  },

  resetStudent(state) {
    state.student = null
  },

  resetProfessional(state) {
    state.professional = null
  },
  setUpdating(state, payload) {
    state.isUpdating = payload
  },
  clearData(state) {
  state.errorMessage =  null
  state.studentSignUpData =  null
  state.professionalSignUpData =  null
  state.professional =  null
  state.student =  null
  state.isLoading =  false
  state.isUpdating =  false
  state.message =  null
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
