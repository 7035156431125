import axios from 'axios';
import { base64ToString } from '../utils/utils';

const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  crossDomain: false,
});

apiInstance.interceptors.request.use((config) => {
  const token = base64ToString(localStorage.getItem('auth-token'));
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// Add a response interceptor
apiInstance.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error)
})

export default apiInstance;
