import apiInstance from '../axios/axios';
import { parseResponseToJSON } from '../utils/utils';

export default {
  async getUniversities() {
    try {
      //
      const res = await apiInstance.get('getUniversityData');
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      //
      throw error;
    }
  },

  async getUniversityData(id) {
    try {
      const res = await apiInstance.get(`getUniversityDataListing/${id}`);
      return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },

  async getAllResearchItems() {
    try {
      const res = await apiInstance.get('get-categories');
      // console.log(res.data);
    } catch (error) {
      throw error;
    }
  },

  async getAllSkills() {
    try {
      const res = await apiInstance.get('getSkills');
      return res.data.data.payload;
    } catch (error) {
      throw error;
    }
  },

  async getStudentContent() {
    try {
      const res = await apiInstance.get('getStudentPageContent');
      return parseResponseToJSON(res.data.data.payload)
      // return res.data.data.payload;
    } catch (error) {
      throw error;
    }
  },

  async getProfessionalContent() {
    try {
      const res = await apiInstance.get('getProfessionalPageContent');
      return parseResponseToJSON(res.data.data.payload)
      // return parseResponseToJSON(res.data.payload);
    } catch (error) {
      throw error;
    }
  },

  // async getGeneralContent() {
  //   try {
  //     const res = await apiInstance.get('getGeneralPageContent');
  //     return parseResponseToJSON(res.data.payload);
  //   } catch (error) {
  //     throw error;
  //   }
  // },

  async addCustomSkills(skills) {
    try {
      apiInstance.post('addSkills', skills);
    } catch (error) {
      throw error;
    }
  },

  async getAllResearchInterests() {
    try {
      const res = await apiInstance.get(`get-categories`);
      return parseResponseToJSON(res.data.data.payload);
    } catch (error) {
      throw error;
    }
  },

  async getJobInterests() {
    try {
      const res = await apiInstance.get('getJobInterests');
      let jobs = []
      const jobsArray = res.data.data.payload[0] // if it ever breaks it is here
      const resData = jobsArray.field_one.forEach((stream) => {
        let ctgsSorted = stream.field_two.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        ctgsSorted.forEach((ctgs) => {
          let subjobs = [];
          ctgs.field_three.forEach((job) => {
            subjobs.push({
              __id: job.id,
              name: job.name,
              catgeory: ctgs.id,
              catgeory_name: ctgs.name,
              stream: stream.id,
              parent: parent.id,
            });
          });
          jobs.push({
            id: ctgs.id,
            name: ctgs.name,
            subjobs: subjobs,
            description: ctgs.description,
          });
        });
      });
      const jobsData = jobs.sort((a, b) => a.name.localeCompare(b.name));
      return parseResponseToJSON(jobsData)
    } catch (error) {
      throw error;
    }
  },

  async getConnectionPurposes() {
    try {
      const {data: {data: {payload}}} = await apiInstance.get('connection-purpose-master');
      return payload
    } catch (error) {
      throw error;
    }
  },
  async getCompaniesByProgrammes (user_data) {
    try {
      const {data:{data: {payload}}} = await apiInstance.post('get-companies-by-programme-ids', user_data)
      return payload
    } catch (error) {
      throw error;
    }
  },
  async getCompaniesByInterests (user_data) {
    try {
      const {data:{data: {payload}}} = await apiInstance.post('get-companies-by-interest-ids', user_data)
      return payload
    } catch (error) {
      throw error;
    }
  },

  async setInterestedJobs (payload) {
    try {
      const res = await apiInstance.post('student-event-role', payload)
      return res
    } catch (error) {
      throw error;
    }
  }
};
